import React, { useState, useEffect, useCallback } from 'react'
import DataTable from '../../../components/NewDataTable/NewDataTable'
import { makeStyles } from '@material-ui/styles'
import ROLES, { INTERNALS } from '../../../constants/roles'
import { Icon, Typography, IconButton, Grid, Button } from '@material-ui/core'
import { useCookies } from 'react-cookie'
import DeleteCompany from '../../../components/forms/PreventionPlanForms/DeleteCompany'
import { useSocialReasonEditorForm } from '../../../containers/FormContainer'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Redirect } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import Access from '../../../containers/RoleContainer'
import {
	GETCOMPANIESPDP,
	MASSIVEACTION,
	COUNT_TO_SENT_DWP,
	SENDTODWP,
	GETSUBCONTRACTORS,
} from '../../../constants/url'
import { bindParams } from '../../../utils/Link'
import api from '../../../utils/Requester'
import TYPES from '../../../constants/pdpType'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import STATUS_DE, { findStatusByCode } from '../../../constants/statusDE'
import PropTypes from 'prop-types'
import ModalSubcontractors from './Modals/ModalSubcontractors'
import { COLUMNS_PDP, getIndexOfColumn } from '../../../utils/Datatable'

const useStyles = makeStyles((theme) => ({
	icon: {
		display: 'block',
		margin: 'auto',
		color: theme.palette.secondary.main,
		cursor: 'pointer',
	},
	deleteIcon: {
		display: 'block',
		margin: 'auto',
		color: '#e02214',
	},
	checkedCircleIcon: {
		display: 'block',
		margin: 'auto',
		color: '#4CAF50',
	},
	removeCircleOutline: {
		display: 'block',
		margin: 'auto',
		color: '#F44436',
	},
	vdBody: {
		display: 'block',
		margin: 'auto',
	},
	vdState: {
		display: 'inline-block',
		width: '40%',
		verticalAlign: 'middle',
	},
	vdArrows: {
		display: 'inline-block',
		width: '50%',
		verticalAlign: 'middle',
	},
	vdArrow: {
		color: '#BABABA',
		display: 'block',
		cursor: 'pointer',
	},
	IconAndSubText: {},
	ISIcon: {
		display: 'block',
		margin: 'auto',
	},
	ISSubText: {
		textAlign: 'center',
		fontSize: 10,
	},
	container: {
		width: '90%',
		marginRight: '5%',
		textAlign: 'center',
	},
	selectedDWP: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.main,
	},
	link: {
		textDecoration: 'none',
		color: theme.palette.secondary.main,
	},
	centeredText: {
		textAlign: 'center',
	},
	subcontractor: {
		textAlign: 'center',
		width: '100%',
		display: 'inline-block',
	},
}))

function DTDE({
	pdpId,
	revisionNumber,
	setRevisionNumber,
	setRedirectRevision,
	isReadOnly,
	refreshDatatable,
	getPdPStats,
}) {
	const classes = useStyles()

	const [cookies] = useCookies()

	const [isRefresh, setRefresh] = useState(false)
	const [result, setResult] = useState({ columns: [], data: [], options: {} })
	const [redirect] = useState(null)

	const [modalState, setModalState] = useState(false)
	const [modalData, setModalData] = useState({})

	const [socialReasonData, setSocialReasonData] = useState()
	const socialReasonForm = useSocialReasonEditorForm(socialReasonData)
	const [isLoading, setIsLoading] = useState(true)

	const [isOpenSubcontractors, setIsOpenSubcontractors] = useState(false)
	const [subcontractors, setSubcontractors] = useState()

	const { enqueueSnackbar } = useSnackbar()

	const recoveryPdpCompanies = (res) => {
		const { data } = res
		if (data) {
			if (isReadOnly) {
				setResult({
					...data,
					options: {
						...data.options,
						selectableRows: 'none',
					},
				})
			} else setResult(data)
		}
		setIsLoading(false)
	}

	const { t } = useTranslation(['user', 'companyRecord', 'common', 'errorCodeATI'])

	const refresh = () => setRefresh(!isRefresh)

	useEffect(() => {
		refresh()
	}, [])

	useEffect(() => {
		refresh()
	}, [refreshDatatable])

	useEffect(() => {
		getPdPStats()
		setIsLoading(true)
		api
			.post(bindParams(GETCOMPANIESPDP, cookies[PLATFORM_ID_COOKIE], pdpId), {
				platformId: cookies[PLATFORM_ID_COOKIE],
				nbItemsAsked: 10,
				pageNumber: 1,
			})
			.then(recoveryPdpCompanies)
	}, [isRefresh])

	const DEStates = {
		VALID: {
			id: 'Valide',
			label: t('companyRecord:validated'),
			icon: 'thumb_up_alt',
			color: '#4CAF50',
		},
		WAITING: {
			id: 'Brouillon',
			label: t('companyRecord:draft'),
			icon: 'watch_later',
			color: '#F5960A',
		},
		WRITING: {
			id: 'Incomplet',
			label: t('companyRecord:rejected'),
			icon: 'create',
			color: '#F5960A',
		},
		REVIVE: {
			id: 'Soumis',
			label: t('companyRecord:submitted'),
			icon: 'autorenew',
			color: '#3399ff',
		},
	}

	const VDWP = {
		AUTO: {
			id: 'Automatic',
			label: t('user:Automatic'),
			icon: 'autorenew',
			color: '#006946',
		},
		DEROGATION: {
			id: 'Derog',
			label: t('user:Derogation'),
			icon: 'redo',
			color: '#F5960A',
		},
		BANNED: {
			id: 'Forbidden',
			label: t('user:Prohibited'),
			icon: 'remove_circle_outline',
			color: '#F44436',
		},
	}

	const IDWP = {
		AUTO: {
			id: 'Received',
			label: t('user:Receive'),
			icon: 'checked_circle',
			color: '#006946',
		},
		DEROGATION: { id: 'Sent', label: t('user:DWPsend'), icon: 'send', color: '#F5960A' },
		BANNED: {
			id: 'NotIssued',
			label: t('user:notSend'),
			icon: 'portable_wifi_off',
			color: '#888888',
		},
		ERROR: {
			id: 'Failed',
			label: t('user:ErrorSend'),
			icon: 'error',
			color: '#F44436',
		},
	}

	const findStateById = (id) =>
		Object.values(DEStates).filter((s) => {
			if (s.id === id) return s
		})[0]

	const findVDWPById = (id) =>
		Object.values(VDWP).filter((s) => {
			if (s.id === id) return s
		})[0]

	const findIDWPById = (id) =>
		Object.values(IDWP).filter((s) => {
			if (s.id === id) return s
		})[0]

	const SwitchMode = ({ label, icon, color }) => {
		return (
			<div className={classes.vdBody}>
				<IconWithSubtext icon={icon} label={label} color={color} />
			</div>
		)
	}
	SwitchMode.propTypes = {
		label: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}

	const IconWithSubtext = ({ icon, label, color, id, dwpErrorCode, tooltip = null }) => {
		return (
			<Tooltip title={tooltip ?? ''} interactive>
				<div className={classes.IconAndSubText}>
					{id === 'Failed' && dwpErrorCode ? (
						<Tooltip title={t(`errorCodeATI:${dwpErrorCode}`)} arrow>
							<Icon className={classes.ISIcon} style={{ color: color }}>
								{icon}
							</Icon>
						</Tooltip>
					) : (
						<Icon className={classes.ISIcon} style={{ color: color }}>
							{icon}
						</Icon>
					)}
					<Typography className={classes.ISSubText} style={{ color: color }}>
						{label}
					</Typography>
				</div>
			</Tooltip>
		)
	}
	IconWithSubtext.propTypes = {
		label: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
		id: PropTypes.number,
		dwpErrorCode: PropTypes.string,
	}

	const delCompany = (p_PdpId, companyId) => {
		setModalData({ pdpId: p_PdpId, companyId: companyId, refresh })
		setModalState(true)
	}

	const editCompany = (p_PdpId, companyId) => {
		setSocialReasonData({
			pdpID: p_PdpId,
			companyId: companyId,
			refresh: () => refresh(),
			setRevisionNumber: setRevisionNumber,
			setRedirectRevision: setRedirectRevision,
		})
		socialReasonForm.open()
	}

	const actionsColumn = {
		label: 'actions',
		name: 'actions',
		options: {
			display: true,
			filter: false,
			sort: false,
			searcheable: false,
		},
	}

	const MassiveActions = ({ selectedRows, displayData }) => {
		const [selectedIds, setSelectedIds] = useState([])

		const [dwpSendingCount, setDSC] = useState(0)
		const [priorVisit, setPriorVisite] = useState(false)
		const [coordMeeting, setCoordMeeting] = useState(false)
		const [massiveAction, setMassiveAction] = useState(null)
		const [dwpMode, setDwpMode] = useState('Automatic')

		const sendingMassiveAction = (res) => {
			if (massiveAction) refresh()
			setMassiveAction(null)

			if (res.data.hasBeenRevised) {
				setRevisionNumber(res.data.revisionNumber)
				setRedirectRevision(true)
			}
		}

		const recoveryDwpCountToSent = (res) => {
			const { data } = res
			if (data) {
				setDSC(data)
			}
		}

		const recoverySendToDwp = (res) => {
			const { ok } = res
			if (!ok && res.data && res.data.length > 0) {
				if (
					res.data[0].code == 'dwp_sending_invalid_de' ||
					res.data[0].code == 'dwp_sending_no_de'
				) {
					enqueueSnackbar(t(res.data[0].code), { variant: 'error' })
					return
				} else {
					enqueueSnackbar(t('user:del_coresp_dwp_sql_error'), { variant: 'error' })
					return
				}
			}

			if (ok) {
				enqueueSnackbar(t('user:DWPSend'), { variant: 'info' })
				refresh()
			}
		}

		useEffect(() => {
			api
				.post(bindParams(MASSIVEACTION, cookies[PLATFORM_ID_COOKIE], pdpId), massiveAction)
				.then(sendingMassiveAction)
		}, [massiveAction])

		useEffect(() => {
			api
				.get(bindParams(COUNT_TO_SENT_DWP, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber))
				.then(recoveryDwpCountToSent)
		}, [])

		const savePriorVisite = () => {
			let data = {
				companyIds: selectedIds,
				actionName: 'PreliminaryVisit',
				actionValue: priorVisit.toString(),
			}
			setMassiveAction(data)
		}

		const saveCoordMeeting = () => {
			let data = {
				companyIds: selectedIds,
				actionName: 'CoordinationMeeting',
				actionValue: coordMeeting.toString(),
			}
			setMassiveAction(data)
		}

		const saveDwpMode = () => {
			let data = {
				companyIds: selectedIds,
				actionName: 'DwpSendingMode',
				actionValue: dwpMode,
			}
			setMassiveAction(data)
		}

		const sendDWP = () => {
			api
				.post(
					bindParams(SENDTODWP, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber),
					selectedIds,
				)
				.then(recoverySendToDwp)
		}

		useEffect(() => {
			setSelectedIds(
				selectedRows.data.map((row) => row.index).map((idx) => displayData[idx].data[15]),
			)
		}, [selectedRows, displayData])
		return (
			<Grid container spacing={8} justify="center" className={classes.container}>
				<Grid item md={4}>
					{redirect && <Redirect to={'/companyRecord/'} />}
					<Typography color="secondary" variant="h6">
						{t('user:Meetings')}
					</Typography>
					{priorVisit ? (
						<IconButton onClick={() => setPriorVisite(false)}>
							<Icon>check_circle</Icon>
						</IconButton>
					) : (
						<IconButton onClick={() => setPriorVisite(true)}>
							<Icon>remove_circle</Icon>
						</IconButton>
					)}
					<Typography color="secondary" variant="body1" inline>
						{t('user:PreliminaryVisit')}
					</Typography>
					<IconButton onClick={() => savePriorVisite()}>
						<Icon color="secondary" className={classes.icon}>
							save
						</Icon>
					</IconButton>
					<br />
					{coordMeeting ? (
						<IconButton onClick={() => setCoordMeeting(false)}>
							<Icon>check_circle</Icon>
						</IconButton>
					) : (
						<IconButton onClick={() => setCoordMeeting(true)}>
							<Icon>remove_circle</Icon>
						</IconButton>
					)}
					<Typography color="secondary" variant="body1" inline>
						{t('user:CoordinationMeeting')}
					</Typography>
					<IconButton onClick={() => saveCoordMeeting()}>
						<Icon color="secondary" className={classes.icon}>
							save
						</Icon>
					</IconButton>
				</Grid>

				<Grid item md={4}>
					<Typography color="secondary" variant="h6">
						DWP
					</Typography>
					<div className={classes.buttonContainer}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => setDwpMode('Automatic')}
							className={classNames({ [classes.selectedDWP]: 'Automatic' === dwpMode })}
						>
							<Icon>autorenew</Icon>
							{t('user:Automatic')}
						</Button>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => setDwpMode('Forbidden')}
							className={classNames({ [classes.selectedDWP]: 'Forbidden' === dwpMode })}
						>
							<Icon>remove_circle</Icon>
							{t('user:Prohibited')}
						</Button>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => setDwpMode('Derog')}
							className={classNames({ [classes.selectedDWP]: 'Derog' === dwpMode })}
						>
							<Icon>reply</Icon>
							{t('user:Derogation')}
						</Button>
					</div>
					<Icon onClick={() => saveDwpMode()} color="secondary" className={classes.icon}>
						save
					</Icon>
				</Grid>

				<Grid item md={4}>
					<Typography color="secondary" variant="h6">
						{t('user:sendDWP')}
					</Typography>
					<Typography color="secondary" variant="h4">
						{dwpSendingCount}
					</Typography>
					<Typography color="secondary" variant="body2">
						{t('user:toBeIssued')}
					</Typography>
					<Button
						variant="outlined"
						onClick={() => sendDWP()}
						className={classes.title}
						color="secondary"
					>
						{t('user:send')}
						<Icon color="secondary" className={classes.dwpIcon}>
							send
						</Icon>
					</Button>
				</Grid>
			</Grid>
		)
	}
	MassiveActions.propTypes = {
		selectedRows: PropTypes.string,
		displayData: PropTypes.string,
	}
	const customToolbarSelect = (selectedRows, displayData) => (
		<MassiveActions selectedRows={selectedRows} displayData={displayData} />
	)

	const openSubcontractors = useCallback((companyId) => {
		api
			.get(bindParams(GETSUBCONTRACTORS, cookies[PLATFORM_ID_COOKIE], pdpId, companyId))
			.then((res) => {
				setSubcontractors(res.data)
				setIsOpenSubcontractors(true)
			})
			.catch((error) => console.log(error))
	})

	return (
		<>
			<ModalSubcontractors
				isOpen={isOpenSubcontractors}
				onClose={setIsOpenSubcontractors}
				subcontractors={subcontractors}
			/>
			{redirect && (
				<Redirect to={'/companyRecord/1/' + redirect.companyRecordId + '/' + redirect.companyId} />
			)}
			{socialReasonForm.formular}
			<DeleteCompany
				open={modalState}
				closeModal={setModalState}
				data={modalData}
				refreshDatatable={isRefresh}
				setRefreshDatatable={setRefresh}
			/>
			<DataTable
				title={t('common:companies')}
				columns={[...result.columns, actionsColumn]}
				data={result.data}
				options={{ ...result.options, customToolbarSelect }}
				isLoading={isLoading}
				customColumns={[
					{
						name: 'subcontractorscount',
						component: function customColumnsComponentSubcontractors(value, tableMeta) {
							return (
								<>
									{value > 0 ? (
										<Button
											onClick={() =>
												openSubcontractors(
													tableMeta.rowData[
														getIndexOfColumn(result.columns, COLUMNS_PDP.COMPANYID)
													],
												)
											}
											className={classes.subcontractor}
										>
											{value}
										</Button>
									) : (
										<span className={classes.subcontractor}>{value}</span>
									)}
								</>
							)
						},
					},
					{
						name: 'type',
						component: function customColumnsComponentType(value) {
							switch (value) {
								case TYPES.principale.code:
									return <img src={TYPES.principale.icon} alt="" />

								case TYPES.subcontract.code:
									return <img src={TYPES.subcontract.icon} alt="" />

								case TYPES.princsub.code:
									return <img src={TYPES.princsub.icon} alt="" />

								case TYPES.error.code:
									return <img src={TYPES.error.icon} alt="" />

								default:
									return <img src={TYPES.error.icon} alt="" />
							}
						},
					},
					{
						name: 'companyrecordstate',
						component: function customColumnsComponentCRState(value, tableMeta) {
							if (!value) return
							let currentState = findStateById(value)
							if (!currentState) return

							const messageRefus =
								tableMeta.rowData[getIndexOfColumn(result.columns, 'messageRefus')]

							return (
								<IconWithSubtext
									icon={currentState.icon}
									label={currentState.label}
									color={currentState.color}
									status={currentState.id}
									tooltip={
										messageRefus ? (
											<div
												style={{ fontSize: '14px' }}
												dangerouslySetInnerHTML={{ __html: messageRefus }}
											/>
										) : (
											''
										)
									}
								/>
							)
						},
					},
					{
						name: 'preliminaryvisit',
						component: (value) => {
							if (value) return <Icon className={classes.checkedCircleIcon}>check_circle</Icon>
							else return <Icon className={classes.deleteIcon}>remove_circle</Icon>
						},
					},
					{
						name: 'coordinationmeeting',
						component: (value) => {
							if (value) return <Icon className={classes.checkedCircleIcon}>check_circle</Icon>
							else return <Icon className={classes.deleteIcon}>remove_circle</Icon>
						},
					},
					{
						name: 'dwpsendingmodecode',
						component: function customColumnsComponentDWPSendingMode(value) {
							let currentVDWP = findVDWPById(value)
							return (
								<SwitchMode
									label={currentVDWP.label}
									icon={currentVDWP.icon}
									color={currentVDWP.color}
								/>
							)
						},
					},

					{
						name: 'dwpstatuscode',
						component: function customColumnsComponentDWPStatusCode(value, tableMeta) {
							if (!value) return
							let currentState = findIDWPById(value)
							if (!currentState) return

							return (
								<IconWithSubtext
									icon={currentState.icon}
									label={currentState.label}
									color={currentState.color}
									id={currentState.id}
									dwpErrorCode={
										tableMeta.rowData[getIndexOfColumn(result.columns, COLUMNS_PDP.DWPERRORCODE)]
									}
								/>
							)
						},
					},
					{
						name: 'creatorfullname',
						component: function customColumnsComponentCreatorFullName(value) {
							return value
						},
					},
					{
						name: 'actions',
						component: function customColumnsComponentActions(value, tableMeta) {
							let entId = tableMeta.rowData[getIndexOfColumn(result.columns, COLUMNS_PDP.COMPANYID)]
							let companyRecord = result.data.find((deid) => deid.companyid === entId)
							let companyRecordId = companyRecord.companyrecordid
							let statusCode =
								tableMeta.rowData[getIndexOfColumn(result.columns, COLUMNS_PDP.COMPANYRECORDSTATE)]

							let currentStatusDE = findStatusByCode(statusCode)
							let isValid = currentStatusDE === STATUS_DE.VALIDE
							let isProcess = currentStatusDE === STATUS_DE.SOUMIS
							return (
								<div>
									<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
										<Icon onClick={() => delCompany(pdpId, entId)} className={classes.icon}>
											clear
										</Icon>
									</Access>
									<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
										<Icon onClick={() => editCompany(pdpId, entId)} className={classes.icon}>
											edit
										</Icon>
									</Access>
									<Access accept={[ROLES.PRINCIPAL]}>
										{companyRecordId &&
											((isProcess && !isValid) ||
												(!isProcess && isValid) ||
												(!isProcess && !isValid)) && (
												<a
													target="_blank"
													rel="noopener noreferrer"
													href={`/companyRecordValidate/${cookies[PLATFORM_ID_COOKIE]}/${pdpId}/${revisionNumber}/${entId}/${companyRecordId}`}
													className={classes.link}
												>
													<Icon className={classes.icon}>search</Icon>
												</a>
											)}
									</Access>
									<Access accept={INTERNALS}>
										{companyRecordId && ((!isProcess && !isValid) || (!isProcess && isValid)) && (
											<a
												target="_blank"
												rel="noopener noreferrer"
												href={
													'/companyRecord/' +
													cookies[PLATFORM_ID_COOKIE] +
													'/' +
													entId +
													'/' +
													companyRecordId
												}
												className={classes.link}
											>
												<Icon className={classes.icon}>search</Icon>
											</a>
										)}
									</Access>
									<Access accept={INTERNALS}>
										{companyRecordId && isProcess && !isValid && (
											<a
												target="_blank"
												rel="noopener noreferrer"
												href={`/companyRecordValidate/${cookies[PLATFORM_ID_COOKIE]}/${pdpId}/${revisionNumber}/${entId}/${companyRecordId}`}
												className={classes.link}
											>
												<Icon className={classes.icon}>search</Icon>
											</a>
										)}
									</Access>
								</div>
							)
						},
					},
				]}
				customLabels={[
					{
						name: 'companyfullname',
						label: t('companyName'),
					},
					{
						name: 'subcontractorscount',
						label: t('subcontractorscount'),
					},
					{
						name: 'revisionnumber',
						label: t('numero_format'),
					},
					{
						name: 'cr-revisionnumber',
						label: t('revdossier'),
					},
					{
						name: 'type',
						label: t('companycontracttype'),
					},
					{
						name: 'companyrecordstate',
						label: t('companyrecordstate'),
					},
					{
						name: 'preliminaryvisit',
						label: t('PreliminaryVisit'),
					},
					{
						name: 'coordinationmeeting',
						label: t('CoordinationMeeting'),
					},
					{
						name: 'dwpstatuscode',
						label: t('dwpstatuscode'),
					},
					{
						name: 'dwpsendingmodecode',
						label: t('dwpsendingmodecode'),
					},
					{
						name: 'operationresponsablefullname',
						label: t('OperationManager'),
					},
					{
						name: 'crprincipal',
						label: t('principal'),
					},
					{
						name: 'creatorfullname',
						label: t('creatorfullname'),
					},
					{
						name: 'isprincipal',
						label: t('mainCompany'),
					},
					{
						name: 'issubcontractor',
						label: t('entrepriseSousTraitante'),
					},
					{
						name: 'dwpsendingmodelabel',
						label: t('dwpsendingmodecode'),
					},
					{
						name: 'dwpstatuslabel',
						label: t('dwpstatuscode'),
					},
					{
						name: 'actions',
						label: t('Action'),
					},
				]}
			/>
		</>
	)
}
DTDE.propTypes = {
	pdpId: PropTypes.string,
	revisionNumber: PropTypes.string,
	setRevisionNumber: PropTypes.func,
	setRedirectRevision: PropTypes.func,
	isReadOnly: PropTypes.bool,
	refreshDatatable: PropTypes.bool,
	getPdPStats: PropTypes.func,
}

export default DTDE
