import { Grid } from '@material-ui/core'
import OpacityOutlinedIcon from '@material-ui/icons/OpacityOutlined'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchUrl } from '../../containers/AuthContainer'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import PropTypes from 'prop-types'
import OICard from '../../components/Cards/OICard'

const useStyles = makeStyles((theme) => ({
	page: {
		paddingTop: theme.spacing.unit * 2,
		paddingLeft: theme.spacing.unit * 10,
		paddingRight: theme.spacing.unit * 10,
		paddingBottom: theme.spacing.unit * 4,
	},
}))

function SpecialsTreatments({ match }) {
	const classes = useStyles()
	const { t } = useTranslation(['specialsTreatments', 'user'])
	const { setTitleBar } = useTotalTitleBarContext()
	const [itemsMenu, setItemsMenu] = useState([])

	useEffect(() => {
		setItemsMenu([
			{
				title: t('specialsTreatments:siret_changement'),
				icon: <OpacityOutlinedIcon />,
				link: matchUrl(match, '/specials-treatments/siret-changement'),
			},
		])
	}, [])

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: {
				icon: 'person',
				title: () => t('specialsTreatments:specials_treatments'),
			},
		})
	}, [setTitleBar, t])

	return (
		<Grid container className={classes.page} spacing={8}>
			{itemsMenu.map((item) => (
				<OICard key={item.title} title={item.title} link={item.link} />
			))}
		</Grid>
	)
}
SpecialsTreatments.propTypes = {
	match: PropTypes.object,
}

export default SpecialsTreatments
