import { Fab, Grid, Checkbox } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import ContactCard from '../../components/Cards/ContactCard'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import HeadTitle from '../../components/HeadTitle'
import { findContactById } from '../../constants/contacts'
import { COMPANY_ID_COOKIE } from '../../constants/cookies'
import { useMatch } from '../../containers/AuthContainer'
import { getContactsByAccountId } from '../../containers/DataContainer'
import { useAddContactForm } from '../../containers/FormContainer'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import PropTypes from 'prop-types'
import Access from '../../containers/RoleContainer'
import ROLES from '../../constants/roles'

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'fixed',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
		zIndex: 1,
	},
	container: {
		padding: theme.spacing.unit,
		paddingBottom: theme.spacing.unit * 10,
	},
	filters: {
		color: theme.palette.secondary.main,
		display: 'inline-block',
		margin: 15,
	},
}))

Contacts.propTypes = {
	match: PropTypes.object,
}
function Contacts({ match }) {
	const classes = useStyles()
	const { t } = useTranslation('user')
	const { setTitleBar } = useTotalTitleBarContext()
	const [cookies] = useCookies()
	const [contacts, setContacts] = useState([])
	const [contactsFilters, setContactsFilters] = useState([])
	const [contactsFiltered, setContactsFiltered] = useState([])
	const [needRefresh, setNeedRefresh] = useState(false)
	const { companyId, formatUrl } = useMatch(match, '/op-infos')

	const targetCompanyID = companyId ? companyId : cookies[COMPANY_ID_COOKIE]
	const changeState = () => {
		setNeedRefresh(true)
	}
	const AddContactForm = useAddContactForm({ companyID: targetCompanyID }, changeState)

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: { icon: 'person', title: () => t('OperationnalData') },
		})
	}, [])

	useEffect(() => {
		getContactsByAccountId(targetCompanyID, function (res, error) {
			if (error) return
			if (res) {
				setContacts(res.data)
				setNeedRefresh(false)
				setContactsFilters(InitializeFilters())
			}
		})
	}, [needRefresh])

	useEffect(() => {
		let result = contacts.filter((contact) => {
			if (contactsFilters.find((filter) => filter.key === 'all').show === true) return true
			if (contactsFilters.some((filter) => filter.show))
				return contactsFilters.some((filter) => filter.show && contact[filter.key])
			return false
		})
		setContactsFiltered(result)
	}, [contactsFilters])

	const newContact = () => {
		AddContactForm.open()
	}

	const AddContact = () => {
		return (
			<ErrorBoundary>
				<Fab className={classes.fab} color="secondary" onClick={newContact}>
					<AddIcon />
				</Fab>
			</ErrorBoundary>
		)
	}

	const InitializeFilters = () => {
		return [
			{
				id: 1,
				key: 'all',
				key_label: 'all',
				show: true,
			},
			{
				id: 2,
				key: 'isNotifiable',
				key_label: 'notifiable_account',
				show: false,
			},
			{
				id: 3,
				key: 'hasAccess',
				key_label: 'account_owner',
				show: false,
			},
		]
	}

	const DisplayFilters = () => {
		return contactsFilters.map((filter) => (
			<div className={classes.filters} key={filter.id}>
				<span>{t(filter.key_label)}</span>
				<Checkbox
					name={`name-checkbox-${filter.id}`}
					className={classes.checkboxChecked}
					onChange={toggleCheckbox}
					checked={filter.show}
					inputProps={{
						'data-value': filter.id,
					}}
				/>
			</div>
		))
	}

	const toggleCheckbox = (event) => {
		let v_contactsFilters = [...contactsFilters]
		if (event.target.checked) {
			let v_filter = v_contactsFilters.find((filter) => filter.id == event.target.dataset.value)
			v_filter.show = true
			if (v_filter.key !== 'all') {
				contactsFilters.find((filter) => filter.key === 'all').show = false
			}
		} else {
			v_contactsFilters.find((filter) => filter.id == event.target.dataset.value).show = false
		}
		setContactsFilters(v_contactsFilters)
	}

	return (
		<>
			<HeadTitle
				title={t('contacts')}
				subtitle={t('warningContactPage')}
				subtitleIcon={<WarningIcon />}
				link={formatUrl}
			/>
			{AddContactForm.formular}

			<DisplayFilters />
			<Grid container spacing={4} direction="row" className={classes.container}>
				{contactsFiltered.map((site, i) => {
					let icons = site.typesIds.map((id) => findContactById(id)[0])
					let links = icons.map((icon) => icon.icon)
					return (
						<ContactCard
							key={i}
							size={3}
							data={{ ...site, companyID: targetCompanyID }}
							typeIcons={links}
							changeState={changeState}
							canEdit={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
						>
							{site.name}
						</ContactCard>
					)
				})}
			</Grid>
			<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
				<AddContact />
			</Access>
		</>
	)
}

export default Contacts
